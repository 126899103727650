<template>
  <div class="dev-group">
    <b-row align-h="between" align-v="center">
      <b-col cols="auto">
        <h2 class="group-title">Icons</h2>
      </b-col>

      <b-col cols="auto">
        <b-input v-model="search" placeholder="search..." debounce="200"/>
      </b-col>
    </b-row>

    <h3>CustomIcons</h3>

    <b-row>
      <b-col v-for="name in customIconNames" :key="`icon-${name}`" cols="6" sm="4" md="3" lg="2" class="mb-3 text-center">
        <b-card bg-variant="light" class="dev-icon">
          <b-icon :icon="name"/>
        </b-card>
        <small class="mt-1 text-break form-text text-muted">{{ name }}</small>
      </b-col>
    </b-row>

    <hr>

    <h3>BootstrapIcons</h3>

    <b-row>
      <b-col v-for="name in bootstrapIconNames" :key="`icon-${name}`" cols="6" sm="4" md="3" lg="2" class="mb-3 text-center">
        <b-card bg-variant="light" class="dev-icon">
          <b-icon :icon="name"/>
        </b-card>
        <small class="mt-1 text-break form-text text-muted">{{ name }}</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { iconNamesKebab } from '@/$plugins/bootstrap/icons/index'

const normalizedNames = iconNamesKebab.map(n => n.replace(/^b-icon-/, ''))
const bootstrapIconNames = normalizedNames.filter(n => n.match(/^b-/))
const customIconNames = normalizedNames.filter(n => !bootstrapIconNames.includes(n))

export default {
  name: 'DevIcons',
  data () {
    return {
      search: ''
    }
  },
  computed: {
    customIconNames () {
      return customIconNames.filter(n => n.indexOf(this.search) >= 0)
    },
    bootstrapIconNames () {
      return bootstrapIconNames.filter(n => n.indexOf(this.search) >= 0)
    }
  }
}
</script>

<style lang="scss">
.dev-icon {
  overflow: hidden;

  .bi {
    font-size: $font-size-base * 2;
    transition: $transition-base;
  }

  &:hover {
    .bi {
      transform: scale(2);
    }
  }
}
</style>
